<template>
  <div v-if="isVisible" class="asideMenu">
    <div class="asideMenuWrapper">
      <div class="asideMenuItem" v-for="(item, index) in items" :key="index">
        <h4 v-if="item.childs">
          <v-icon size="20"> {{ item.icon }}</v-icon>
          {{ item.label }}
        </h4>
        <router-link v-else :to="item.to">
          <h4
            class="navTitle"
            :class="isCurrentRoute(item.to) && 'isCurrentRoute'"
          >
            <v-icon size="20"> {{ item.icon }}</v-icon>
            {{ item.label }}
          </h4>
        </router-link>
        <router-link
          :to="child.to"
          v-for="(child, childId) in item.childs"
          :key="childId"
        >
          <p :class="isCurrentRoute(child.to) && 'isCurrentRoute'">
            {{ child.label }}
          </p>
        </router-link>
      </div>
      <button class="disconnectButton" @click="logout">
        <v-icon size="16"> mdi-logout </v-icon>
        Déconnexion
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {
      items: [
        {
          icon: "mdi-settings-outline",
          label: "Compte",
          childs: [
            {
              to: "/settings/shop",
              label: "Site",
            },
            {
              to: "/settings/profile",
              label: "Profil",
            },
            {
              to: "/settings/wifi-settings",
              label: "Paramètres WiFi",
            },
            {
              to: "/settings/notifications",
              label: "Notifications",
            },
          ],
        },
        {
          icon: "mdi-key-outline",
          label: "Access",
          childs: [
            {
              to: "/settings/access-settings",
              label: "Paramètres",
            },
          ],
        },
        {
          icon: "mdi-shopping-outline",
          label: "Marketing",
          childs: [
            // {
            //   to: "/settings/wifi-analytics",
            //   label: "WiFi Analytics",
            // },
            {
              to: "/settings/qr-code",
              label: "QR Code",
            },
            {
              to: "/settings/segments",
              label: "Segments",
            },
            {
              to: "/settings/tags",
              label: "Tags",
            },
          ],
        },
        {
          icon: "mdi-cash-multiple",
          label: "Facturation",
          childs: [
            {
              to: "/settings/billing-overview",
              label: "Aperçu",
            },
            {
              to: "/settings/billing-invoices",
              label: "Factures",
            },
            {
              to: "/settings/billing-payment-methods",
              label: "Méthodes de paiement",
            },
            {
              to: "/settings/plan",
              label: "Changer de plan",
            },
          ],
        },
        {
          icon: "mdi-share-variant-outline",
          label: "Intégrations",
          to: "/settings/integrations",
        },
      ],
    };
  },
  computed: {
    isVisible() {
      if (this.$route.meta.primary === "settings") return true;
      return false;
    },
  },
  methods: {
    logout() {
      this.$router.push("/logout");
    },
    isCurrentRoute(route) {
      return this.$route.path.includes(route);
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/global";

.asideMenu {
  color: $slate;
  width: 20vw;
  padding: 2rem;
  .asideMenuWrapper {
    width: 100%;
    height: 100%;
    .asideMenuItem {
      padding-bottom: 1.25rem;
      margin-bottom: 1.25rem;
      border-bottom: 1.25px solid $grey-medium;
      h4 {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 1rem;
        i {
          margin-right: 0.5rem;
          color: $slate;
        }
      }
      .navTitle {
        margin-bottom: 0;
        color: $slate !important;
        transition: all 0.3s ease;
        &:hover {
          color: $primary !important;
          i {
            color: $primary !important;
          }
        }
      }
      p {
        color: $slate !important;
        font-size: 14px;
        line-height: 2rem;
        transition: all 0.3s ease;
        &:hover {
          color: $primary !important;
        }
      }
      .isCurrentRoute {
        color: $primary !important;
        i {
          color: $primary !important;
        }
      }
    }
    .disconnectButton {
      margin-bottom: 2rem;
      font-weight: 500;
      color: $slate;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;
      i {
        margin-right: 0.3rem;
        color: $slate;
      }
      &:hover {
        color: $primary;
        i {
          color: $primary;
        }
      }
    }
  }
}
</style>
