import * as Storage from "@/core/js/utils/utils.js";
import axios from "axios";
import Vue from "vue";

const SET_USER = "SET_USER";
const SET_TOKEN = "SET_TOKEN";
const SET_PING = "SET_PING";
const SET_MAILING_LIST = "SET_MAILING_LIST";
const SET_SHOPPER_LIST = "SET_SHOPPER_LIST";

const AUTHENTICATE_URL = "/app/keeper/authenticate";
const FORGOTTEN_PWD_URL = "/app/auth/password/forgotten";
const CHANGE_PWD = "/app/keeper/api/account/change-password";
const ACCOUNT_URL = "/app/keeper/api/account/me";
const MAILING_LIST_URL = "/app/keeper/api/support/mlproviders";
const SERVICE_URL = "/app/keeper/api/mailinglist/";
const SHOPPER_LIST_URL = "/app/keeper/api/data/shopper/list?size=5";
const REPORTS_URL = "/app/keeper/api/preference/report";
const RESET_PWD_URL = "/app/auth/password/reset";

const userStore = {
  namespaced: true,
  state: {
    token: Storage.get("soy_keeper_token") || null,
    user: JSON.parse(Storage.get("soy_keeper_user")) || null,
    categories: JSON.parse(Storage.get("soy_keeper_categories") || null),
    status: "",
    tokenExpired: false,
    ping: false,
  },
  mutations: {
    [SET_TOKEN](state, value) {
      state.token = value;
    },
    [SET_USER](state, value) {
      state.user = value;
    },
    [SET_PING](state, value) {
      state.ping = value;
    },
    [SET_MAILING_LIST](state, value) {
      state.mailinglist = value;
    },
  },
  actions: {
    login({ commit, dispatch }, credentials) {
      return axios
        .post(Storage.getAllUrl(AUTHENTICATE_URL), {
          username: credentials.username,
          password: credentials.password,
          remember_me: credentials.remember_me,
        })
        .then((response) => {
          const token = response.data.jwt;
          Vue.$cookies
            .set("isloggedinSession", "logged", 0)
            .set("isloggedinDuration", "logged", "1d");
          Storage.set("soy_keeper_token", token);
          commit(SET_TOKEN, token);
          dispatch("fetchUser");
        })
        .catch((err) => {
          throw err;
        });
    },
    logout({ commit }) {
      return new Promise((resolve) => {
        commit(SET_TOKEN, null);
        commit(SET_USER, null);
        Storage.remove("soy_keeper_token");
        Storage.remove("soy_keeper_user");
        Vue.$cookies.remove("isloggedinSession");
        Vue.$cookies.remove("isloggedinDuration");
        resolve("ok");
      });
    },
    forgotPassword(_, data) {
      return axios({
        url: Storage.getAllUrl(FORGOTTEN_PWD_URL),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "X-Client-Type": "board_keeper",
        },
        method: "post",
        data: data,
      });
    },
    resetPassword(_, data) {
      return axios({
        url: Storage.getAllUrl(RESET_PWD_URL),
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        method: "post",
        data: data,
      });
    },
    changePassword({ state }, credentials) {
      return axios({
        url: Storage.getAllUrl(CHANGE_PWD),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: {
          password: credentials.password,
          new_password: credentials.newPassword,
        },
      })
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          throw err;
        });
    },
    isLoggedIn({ commit, state }) {
      const storageToken = Storage.get("soy_keeper_token");
      const stateToken = state.token;
      let res = false;
      if (stateToken) {
        res = true;
      } else if (storageToken) {
        commit(SET_TOKEN, storageToken);
        res = true;
      }
      return Promise.resolve(res);
    },
    fetchUser({ commit, state }) {
      return axios({
        method: "get",
        url: Storage.getAllUrl(ACCOUNT_URL),
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          if (response.data) {
            Storage.set("soy_keeper_user", JSON.stringify(response.data));
            commit(SET_USER, response.data);
          }
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    putUser({ state, dispatch }, user) {
      return axios({
        url: Storage.getAllUrl(ACCOUNT_URL),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: user,
      })
        .then((resp) => {
          dispatch("fetchUser");
          return resp;
        })
        .catch((err) => {
          throw err;
        });
    },
    ping({ commit, state }) {
      commit(SET_PING, true);
      return axios({
        method: "get",
        url: Storage.getAllUrl(ACCOUNT_URL),
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then(() => {
          commit(SET_PING, false);
          return true;
        })
        .catch(() => {
          commit(SET_PING, false);
        });
    },
    fetchMailingList({ commit, state }) {
      return axios({
        method: "GET",
        url: Storage.getAllUrl(MAILING_LIST_URL),
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then((response) => {
          commit(SET_MAILING_LIST, response.data);
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchServiceProperties({ state }, serviceName) {
      return axios({
        method: "GET",
        url: Storage.getAllUrl(SERVICE_URL) + serviceName,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    putServiceProperties({ state }, payload) {
      return axios({
        method: "PUT",
        url: Storage.getAllUrl(SERVICE_URL) + payload.name,
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
        data: {
          privkey: payload.privkey,
          active: payload.active,
        },
      })
        .then((response) => {
          return response;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchShopperList({ commit, state }) {
      return axios({
        method: "get",
        url: Storage.getAllUrl(SHOPPER_LIST_URL),
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          commit(SET_SHOPPER_LIST, response.data);
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    fetchReports({ state }) {
      return axios({
        method: "GET",
        url: Storage.getAllUrl(REPORTS_URL),
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    putReports({ state }, preferences) {
      return axios({
        url: Storage.getAllUrl(REPORTS_URL),
        method: "PUT",
        headers: {
          Authorization: `Bearer ${state.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: preferences,
      })
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    user: (state) => state.user,
    token: (state) => state.token,
    pingging: (state) => state.ping,
    isZyxelProvider: (state) => state.user?.network?.cloud_provider === "ZYXEL",
    networkId: (state) => state.user?.network?.network_id,
  },
};

export default userStore;
