import Vue from "vue";
import Vuex from "vuex";
import billingStore from "./billing.store";
import guestSetupStore from "./guestSetup.store";
import msgStore from "./msg.store";
import navStore from "./nav.store";
import shopStore from "./shop.store";
import ssidStore from "./ssid.store";
import userStore from "./user.store";
import segmentsStore from "./segments.store";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    userStore: userStore,
    msgStore: msgStore,
    billingStore: billingStore,
    ssidStore: ssidStore,
    shopStore: shopStore,
    navStore: navStore,
    guestSetupStore: guestSetupStore,
    segmentsStore: segmentsStore,
  },
});

export default store;
