<template>
  <div v-resize="onResize">
    <div v-if="!contentLoaded" class="loading">
      <AppLoader />
    </div>
    <div v-else-if="isMobile" class="soy-container mobileVersion">
      <MobileBase />
    </div>
    <div v-else class="soy-container">
      <secondary-menu class="secondary-menu" />
      <AsideMenu />
      <div class="soy-content">
        <router-view />
      </div>
      <WaitingPage />
      <not-allowed-modal />
    </div>
  </div>
</template>

<script>
import SecondaryMenu from "@/core/components/secondary-menu.vue";
import menuConfig from "@/core/js/config/menuconfig";
import { mapGetters } from "vuex";
import WaitingPage from "@/core/components/WaitingPage";
import NotAllowedModal from "@/core/components/NotAllowedModal.vue";
import MobileBase from "@/views/MobileBase.vue";
import AppLoader from "@/core/components/AppLoader";
import AsideMenu from "./components/AsideMenu.vue";

export default {
  name: "settingsPage",
  title: "Soy Club Account",
  components: {
    SecondaryMenu,
    WaitingPage,
    NotAllowedModal,
    MobileBase,
    AppLoader,
    AsideMenu,
  },
  data() {
    return {
      contentLoaded: false,
      menuItems: [],
      isMobile: false,
    };
  },
  computed: {
    ...mapGetters("userStore", ["user"]),
    yellowUserLetter() {
      return this.user && this.user.firstname ? this.user.firstname[0] : null;
    },
    emptyFlowImage() {
      return require("@/assets/images/nap.svg");
    },
  },
  mounted() {
    if (!this.user) {
      this.$store.dispatch("userStore/fetchUser").then(() => {
        setTimeout(() => {
          this.contentLoaded = true;
        }, 500);
      });
    } else {
      this.contentLoaded = true;
    }
    this.selectMenu();
  },
  updated() {
    this.selectMenu();
    this.$mixpanel.track("Page view", {
      page: this.$router.currentRoute.meta.secondary,
    });
  },
  methods: {
    selectMenu() {
      let items = menuConfig.menu;
      let currentRouteName = this.$router.currentRoute.meta.primary;

      let result = items.filter((i) => i.name === currentRouteName);
      this.menuItems = result[0].value;
    },
    onResize() {
      if (window.innerWidth < 768) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/global";

.mobileVersion {
  display: flex;
  flex-direction: column;
}

.mobile-header {
  grid-column: 2 / span 7;
  grid-row: 1/3;
  background: #000000 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 4px #00000029;
  opacity: 1;
  color: white;
}

@include md {
  .soy-container {
    .soy-content {
      grid-row: 2/7;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.avatar-wrapper {
  height: 52px;
  width: 52px;
  background-color: $yellow;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2em;
  &:hover {
    cursor: pointer;
  }
}

.links {
  display: flex;
  height: 100%;
  justify-content: flex-start;
  align-items: center;
  .link {
    display: flex;
    align-items: center;
    height: 100%;
    width: 170px;
    justify-content: center;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    border-right: solid 1px #e8e9ec;
    &:last-child {
      border: none;
    }
    &:hover {
      cursor: pointer;
    }
    &.selected {
      background-color: $grey-medium;
    }
    .icon-wrapper {
      display: flex;
      align-items: center;
    }
    span {
      margin-left: 10px;
      font-size: 28px;
      line-height: 38px;
      font-weight: 600;
    }
  }
}
.user-info {
  display: flex;
  align-items: center;
  justify-content: center;
  .help-wrapper {
    border-right: solid 1px $grey-light;
    height: 50px;
    padding-right: 20px;
    width: 50px;
    box-sizing: border-box;
    display: flex;
    img {
      margin: auto;
      height: auto;
      width: auto;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .avatar-wrapper {
    height: 52px;
    width: 52px;
    background-color: $yellow;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 2em;
    }
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
