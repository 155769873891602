export const getSegmentsUrl =
  process.env.VUE_APP_API_URL + "/app/keeper/api/data/segment/list";

export const deleteSegmentsUrl = (segmentId) =>
  process.env.VUE_APP_API_URL +
  "/app/keeper/api/data/segment/delete/" +
  segmentId;

export const createSegmentsUrl =
  process.env.VUE_APP_API_URL + "/app/keeper/api/data/segment/upsert";
