import axios from "axios";
import {
  getSegmentsUrl,
  deleteSegmentsUrl,
  createSegmentsUrl,
} from "@/shared/endpoints";

const SET_SEGMENTS = "SET_SEGMENTS";
const ADD_SEGMENT = "ADD_SEGMENT";
const REMOVE_SEGMENT = "REMOVE_SEGMENT";

const segmentsStore = {
  namespaced: true,
  state: {
    segments: [],
  },
  mutations: {
    [SET_SEGMENTS](state, segments) {
      state.segments = segments;
    },
    [ADD_SEGMENT](state, segment) {
      const index = state.segments.findIndex(
        (s) => s.segment_id === segment.segment_id
      );
      if (index !== -1) {
        state.segments.splice(index, 1, segment);
      } else {
        state.segments.push(segment);
      }
    },
    [REMOVE_SEGMENT](state, segmentId) {
      state.segments = state.segments.filter(
        (segment) => segment.id !== segmentId
      );
    },
  },
  actions: {
    fetchSegments({ rootState, commit }) {
      return axios({
        method: "get",
        url: getSegmentsUrl,
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then((response) => {
          commit(SET_SEGMENTS, response.data);
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
    deleteSegment({ rootState, commit }, segmentId) {
      return axios({
        method: "delete",
        url: deleteSegmentsUrl(segmentId),
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
      })
        .then(() => {
          commit(REMOVE_SEGMENT, segmentId);
        })
        .catch((err) => {
          throw err;
        });
    },
    createSegment({ rootState, commit }, segment) {
      return axios({
        method: "post",
        url: createSegmentsUrl,
        headers: {
          Authorization: `Bearer ${rootState.userStore.token}`,
          "Content-Type": "application/json;charset=UTF-8",
        },
        data: segment,
      })
        .then((response) => {
          commit(ADD_SEGMENT, response.data);
          return response.data;
        })
        .catch((err) => {
          throw err;
        });
    },
  },
  getters: {
    segments: (state) => state.segments,
  },
};

export default segmentsStore;
